import React, { createRef, useEffect, useState } from "react";
import {
  faArrowLeftLong,
  faCheckSquare,
  faCloudShowersHeavy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import { gaEvent } from "../../..";
// import { useScreenshot } from "use-react-screenshot";
const base_URL = ".././data.json";
const ref = createRef(null);

const noOfferStrings = [
  `We’re getting mixed signals. Find an amount with a ₹ or a % symbol and scan again.`,
  `UFO spotted! Unidentifiable Freaking Offer. Find an amount with a ₹ or a % symbol and scan again.`,
  `Like a Nolan movie, we didn’t understand that. Find an amount with a ₹ or a % symbol and scan again.`,
  `Yeh kya hua, kaise hua, kyun hua… Find an amount with a ₹ or a % symbol and scan again.`,
  `Some things were lost in translation. Find an amount with a ₹ or a % symbol and scan again.`,
];

const NoOffer = ({
  image,
  setPage,
  offer = null,
  scannedValue = null,

}) => {
  const [offerText, setOfferText] = useState();

  const scanAgain = () => {
    gaEvent('Scan Again','');
    setPage(null);
  };

  useEffect(() => {
    if (!offer?.Title) {
      setOfferText(getRandomOfferString());
    } else {
      setOfferText(offer.Title);
    }
  }, [image,offer]);
  const getRandomOfferString = () => {
    return noOfferStrings[
      Math.floor(Math.random() * (noOfferStrings.length - 1 + 1))
    ];
  };

  return (
    <div ref={ref} className="page-style-offer">
      {/* C:\Users\pram5\Downloads\ocr\public\Assets\clouds.png */}
      <Header />
      {/* <img src='./Assets/clouds.png' id='clouds'></img> */}
      <div className="main-cont">
        <div className="offer-row">
          <div className="offer-col1">
            <img src={image}></img>
            {/* <img src='./Assets/Image_Top-Left.png'></img> */}
          </div>
          <div className="offer-col2">
            <p>{offerText}</p>
          </div>
        </div>
        <div className="offer-row2">
          <img src={`./offerImages/25.png`}></img>
        </div>
      </div>
      <div className="offer-btn">
        {/*   <button onClick={tripConfirm} id="offer-btn" style={{ color: "black" }}>
          <p>
            <img src="./Assets/Black_Tick.png" id="tick"></img>&nbsp;I want this
            trip
          </p>
        </button> */}
        <button 
          onClick={scanAgain} 
          id="offer-btn" 
          style={{ marginBottom: "20px" }}
          >
          <p>I want to scan more</p>
        </button>
      </div>
      <img src="./Assets/clouds.png" id="clouds"></img>
    </div>
  );
};
export default NoOffer;
