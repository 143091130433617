export const offerBelow50 = [
  `Did we time travel?
  Kyunki an offer so low only belongs to the 1920s.
  Please scan for more than ₹50.
  `,
  `Your plans and this amount - both need a raise.
  Please scan for more than ₹50.`,
  `Find a ladder.
  Because you need to go higher than that price.
  Please scan for more than ₹50.`,
  `Itne mein itnaich nahi milega.
  Please scan for more than ₹50.
  `,
];

export const offerBetween50And199 = [
  `Finance 101: a slightly bigger investment can give you much bigger returns.`,
  `Pro tip: with a little extra money, take a pro trip instead ;) All the way to a queen-sized bed!`,
  `The price of a phone cover.
That’s all you need to upgrade from this bargain deal to a vacay feel.`,
  `All you need is one nine nine,
to recline and feel fine.`,
  `We’re no fortune tellers but we see you living in luxury for a little more money.`,
];

export const offerBetween199And1498 = [
  `This offer is sweet. But this suite offer is better.`,
  `Ask yourself, will this make you as happy as a trip to the Malabar Coast? Don’t think so.`,
  `Don’t make your wallet suffer with this deal when you can do India ka safar with this deal.`,
];

export const offerBetween1499And6998 = [
  `Akkad bakkad Bombay bo.
Instead of this offer,
Bombay vacation lelo.`,
  `Adding another item to your monthly spends < Adding palace tours to your yearly travels.
`,
  `Retail therapy can wait. 
Soothe your soul with a relaxing staycation instead.`,
];

export const offerBetween6999And1200 = [
  `Do you really need ek aur cheez…
when you could be on a gorgeous beach saying cheese instead?`,
  `Your house may not need more things. But your heart could use a Thai holiday.`,
];

export const offerAbove1200 = [
  `When Bangkok tickets are this pocket friendly,
get on the flight and even stay one more night.
`,
  `Pawri hone wali hai!
For that amount, fly yourself and your friends out to Asia’s most raving city.`,
  `Wouldn’t it feel nice,
to not just fly there but also fill up on some Thai curry with rice?`,
  `When your money jiggle jiggles and folds, let it take you to Japan and eat some authentic sushi.`,
  `All this cash and you’re still here?
Go global with domestic prices.`,
  `It’s not how much money you have, it’s what you make of that money.`,
];


export const offerBetween5To35=[
    `When you can fill up your image gallery, do you really want to fill up your drawers instead?`,
`Kisi disco mein jaein? Kisi hotel mein khaein? Ditch this offer and pick a hotel.`,
`Why settle for this deal when you can take off with this deal instead.`,
`Why sink your paisa on this, when you can sink into comfy hotel beds instead.`,
`We spoke to your future room tour reels. They want to be shot ASAP.`,
`Why spend on this, when you can bask in some lavish bliss?`
]

export const offerBetween36To55 = [
    `Room with a view >>>> 
Gadget with an EMI`,
`Stop buying shiny, new things.
Start touring shiny, new cities. `,
`London Bridge is calling you my fair people.
So, skip this offer and skip to the good part.
`,
`Your bank balance will give you 5 stars if you relax in a 3 star instead of buying this.`
]

export const offerBetween56To70=[
    `Say Du-bye to this offer and say hello to a Dubai trip instead.
Doesn’t vacationing like a celeb sound better than spending like one?`,
`Instead of ticking things off your grocery list, tick Phuket off your bucket list.`,
`If we were you, we’d choose another stamp on our passport & chill,
Instead of getting another product bill.`,
`Twinkle twinkle little star,
tu swanky hotel mein chill kar yaar.`
]