// import axios from "axios";

// export const baseUrl =
//   "https://2gw0e65hcc.execute-api.eu-north-1.amazonaws.com/prod/ocr";

// const uploadImage = async (date) => {
//   return await fetch(`${baseUrl}`, {
//     method: "PUT",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application.json",
//     },
//     body: JSON.stringify(date),
//   });
// };

// const getTextFromImage = async (targetImage) => {
//   console.log(targetImage,"...inside api")
//  return axios
//     .post(
//         `${baseUrl}`,
//       JSON.stringify(targetImage)
//     );
// };

// export const apis = {
//   uploadImage,
//   getTextFromImage
// };


import axios from 'axios';

const API_KEY = 'AIzaSyCb-uE9yqnGnB7dSVusGbhNFuAFYh1j5vI'; // Replace with your API key
const endpoint = `https://vision.googleapis.com/v1/images:annotate?key=${API_KEY}`;

const getTextFromImage = async (base64Image) => {
console.log("iniside api call starting")

  const requestPayload = {
    requests: [
      {
        image: {
          content:base64Image,
        },
        features: [
          {
            type: 'TEXT_DETECTION',
          },
        ],
      },
    ],
  };
  try {
    console.log(base64Image, "..........inside api");
    const response = await axios.post(endpoint, requestPayload);
    console.log(response.data.responses[0].textAnnotations[0].description, "..........inside api after response!!!");
    return { response: response.data.responses[0].textAnnotations[0].description };
  } catch (error) {
    console.error('Error fetching data from Vision API:', error);
    return null;
  }
};

export const apis = {
  getTextFromImage,
};