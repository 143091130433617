
import React from 'react'
import { gaEvent } from '../../..'
import './scanOffer.css'




export default function ScanOffer({setPage}) {

    const changeHandler=()=>{
        gaEvent('I am Ready to Take Off');
       setPage('offer')
    }


    
   
  return (
    <div className='main-con'>
    <div className='container'>
        <img src='./Assets/Logo.png' alt='logo' id="logo"></img>
        <div className='row'> 
          <div className='col'>
            <img src='./Assets/Celebrates.png' alt='sf'></img>
          </div>
          <div className='col2'>
            <img src='./Assets/Top_Part.png' alt='welcome pic' id="front-img"></img>
          </div>
            <button onClick={changeHandler}><img src='./Assets/Orange_Tick.png' id="tick"></img>&nbsp; I'm ready</button>
          <div className='partner-row'>
            <img src='./Assets/Ranking_Partners.png' id="partner1"></img>
            <img src='./Assets/Bottom_Logos_2.png' id="partner2"></img>
          </div>
      
        </div>
      </div> 
    </div>
  )
}