
import React from "react"

const Header=()=>{
return(
    <div className="header">
    <div className="header-row">
       <img src="./Assets/Logo.png" style={{height:"3vh",marginLeft:".7rem"}}></img>
       <img src="./Assets/Tagline.png" style={{height:"4vh",marginRight:".6rem"}}></img>
       </div>
    </div>
)
}

export default Header