import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FormData from "form-data";
import { Camera, CameraType } from "./Camera";
import { Bars } from "react-loader-spinner";
import Offer from "./Camera/components/pages/Offer";
import MinOffer from "./Camera/components/pages/MinOffer";
import MaxOffer from "./Camera/components/pages/MaxOffer";
import Header from "./Camera/components/pages/Header";
import "./index.css";
import { apis } from "./api";
import offerList from "./data.json";
import { shuffle } from "./utils/utils";
import { gaEvent } from ".";
import NoOffer from "./Camera/components/pages/NoOffer";
import { useReactMediaRecorder } from "react-media-recorder";
import { offerAbove1200, offerBelow50, offerBetween1499And6998, offerBetween199And1498, offerBetween36To55, offerBetween50And199, offerBetween56To70, offerBetween5To35, offerBetween6999And1200 } from "./Text";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
declare global {
  interface Window {
    ref: any;
    extractCuratedText:any;
  }
}
const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Control = styled.div`
  position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    right: 0;
    width: 20%;
    /* min-width: 130px; */
    /* min-height: 130px; */
    height: 100%;
    /* background: rgb(255 255 255 / 80%); */
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* padding: 50px; */
    /* box-sizing: border-box; */
    /* -webkit-flex-direction: column-reverse; */
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;

  @media (max-aspect-ratio: 5/1) {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    /* flex-direction: row; */
    bottom: 0;
    display: flex;
    width: 100%;
    height: 19%;
}
  }

  @media (max-width: 400px) {

  }
`;
const Control_inner = styled.div`
  position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    right: 0;
    width: 20%;
    /* min-width: 130px; */
    /* min-height: 130px; */
    height: 100%;
    /* background: rgb(255 255 255 / 80%); */
    z-index: 10;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* padding: 50px; */
    /* box-sizing: border-box; */
    /* -webkit-flex-direction: column-reverse; */
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;

  @media (max-aspect-ratio: 5/1) {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    /* flex-direction: row; */
    bottom: 0;
    display: flex;
    width: 100%;
    height: 19%;
}
  }

  @media (max-width: 400px) {

  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

const FullScreenImagePreview = styled.div<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const App = () => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState<string | null>(null);
  const [showImage, setShowImage] = useState<boolean>(false);
  const camera = useRef<CameraType>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [offerType, setOfferType] = React.useState(-1); //0 for low | 1 for offer | 2 for high | -1 for no offer
  const [offerHeadline, setOfferHeadline] = useState("");

  const [offer, setOffer] = useState({});

  const [page, setPages] = useState(null);
  const [text, setText] = useState("");

  // screen recording
/*   const {
    status,
    startRecording: startRecord,
    stopRecording: stopRecord,
    mediaBlobUrl,
  } = useReactMediaRecorder({ screen: true, video: false, audio: false }); */

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == "videoinput");
      setDevices(videoDevices);
    })();
  }, []);

  const getText = async (photo) => {
    console.log("inside getText function")
    // var FormData = new FormData();

    //let blob = dataURItoBlob(photo);

    setLoading(true);

    const UID = Math.round(1 + Math.random() * (1000000 - 1));
    const base64Image = photo.split(',')[1];
    var imagePayload = {
      fileExt: "png",
      imageID: UID,
      folder: UID,
      img: base64Image,
    };
    // console.log(base64Image,"---------")
    

    // await apis.uploadImage(imagePayload).then((response)=>{
    //     console.log(response,"res of api.............");
    //     // setText(res.data.body);
    //     // extractCuratedText(res.data.body);
    // }).catch((err) => {
    //   console.log('unable to upload image',err);
    //   setNoOfferFound();
    // });

    // const response = await uploadToS3(blob,fileName);

    let targetImage = UID + ".png";

    apis.getTextFromImage(base64Image)
  .then((res) => {
    if (res && res.response) {
      setText(res.response);
      extractCuratedText(res.response);
    } else {
      console.log('No text found in image');
      setNoOfferFound();
    }
  })
  .catch((error) => {
    console.log('Unable to extract image', error);
    setNoOfferFound();
  });

  };


  const getCopyArray = (amount,type)=>{


    if(type=='price'){
      if(amount>200 && amount<=1498){
        return offerBetween199And1498;
      }else if(amount >1499 && amount<=6998){
        return offerBetween1499And6998;
      }else if (amount >6999 && amount<=1200){
        return offerBetween6999And1200;
      }else{
        return offerAbove1200;
      }
    }else{


      if(amount>5 && amount<=35){
        return offerBetween5To35;
      }else if(amount >35 && amount<=55){
        return offerBetween36To55;
      }else{
        return offerBetween56To70;
      }



    }
  


  }

  const extractCuratedText = (text) => {
    if (text == "") {
      console.log('no string detected',text);
      setNoOfferFound();
      return;
    }
    const textArray = text?.split(" ");
    
    for (let index = 0; index < textArray.length; index++) {
      const word = textArray[index];
console.log(word,"-------------")
      if (word.includes("₹")) {
        setOfferHeadline(word);

        const amount =
          word.length == 1
            ? Number(textArray[index + 1].replace(/[^0-9.]/g, ""))
            : Number(word.replace(/[^0-9.]/g, ""));
        console.log(amount,"amount ..............")
        //if amount is less than 50
        if (amount <= 50) {
          console.log('amount is < 50 ',amount);
          setOfferType(-1);
          //setPages("offer");

          //if scanned image is below 50 thn random text will be shown on no offer page
          const newOffer = {
            Title:
              offerBelow50[Math.floor(Math.random() * offerBelow50.length)],
          };

          setOffer(newOffer);
          setNoOfferFound();
        }
        //amount is between 50 to 200
        else if (amount > 50 && amount <= 199) {
          setOfferType(1);

          let newOffer = offerList.find((item) => {
            return Number(item.Amount) == 199 && item.Type == "ByPrice";
          });

          newOffer = {
            ...newOffer,
            Title:
              offerBetween50And199[
                Math.floor(Math.random() * offerBetween50And199.length)
              ],
          };
          
          setOffer(newOffer);
          console.log('amount is > 50 ',newOffer);
          setPages("offer");
        } else if (amount >= 200 && amount <=6999) {
          //amount between 200 and highest offer
          setOfferType(2);
          console.log('amount is > 200 ');



        

         let newOffer = {
            ...fetchOffers(amount, "ByPrice"),
          };
          console.log('amount is > 200 ',newOffer);
          setOffer(newOffer);
          setPages("offer");
        } else if (amount >6999 && amount <=12000) {
          //amount between 200 and highest offer
          setOfferType(2);
          console.log('amount is > 200 ');


          // const copyArray = getCopyArray(amount,'price');

         let newOffer = {
            ...fetchOffers(amount, "ByPrice"),
            // Title:
            // copyArray[
            //     Math.floor(Math.random() * copyArray.length)
            //   ],
          };
          console.log('amount is > 200 ',newOffer);
          setOffer(newOffer);
          setPages("offer");
        } 
        else if (amount >12000) {
          //amount between 200 and highest offer
          setOfferType(2);
          console.log('amount is > 12000 ');


          // const copyArray = getCopyArray(amount,'price');

         let newOffer = {
            ...fetchOffers(amount, "ByPrice"),
            // Title:
            // copyArray[
            //     Math.floor(Math.random() * copyArray.length)
            //   ],
          };
          console.log('amount is > 12000 ',newOffer);
          setOffer(newOffer);
          setPages("offer");
        } 
        else {
          console.log('no offer found ');
          setNoOfferFound();
        }
        break;

      } else if (word.includes("%") && !text.includes("₹")) {
        console.log("percentage",word)
        const percentValue = checkIfHasNumber(word, textArray, index);
        if (typeof percentValue==="number" && percentValue> 0) {


          // const copyArray = getCopyArray(percentValue,'discount');

          const of = fetchOffers(percentValue, 'ByDiscount');
          
          
          if(of){

            let newOffer = {
              ...of,
              // Title:
              // copyArray[
              //     Math.floor(Math.random() * copyArray.length)
              //   ],
            };
            setOffer(newOffer);
            setPages('offer')
          }else{
            setNoOfferFound();
          }
        
  




        } else {
          setNoOfferFound();
        }
        break;
      } else {
       setNoOfferFound();
       
      }
    }

    setIsOpen(true);
    setTimeout(()=>{
      setLoading(false);
    },500);


  };


  window.extractCuratedText = extractCuratedText;
  /* 
    Case 02 : ₹ symbol followed by Numbers
  Offer will be shown with lesser value 
  If the value scanned is higher than our highest offer a random offer from 5000 to the higher value will be show
  If the value scanned is lower than 0-50 a random copy appears from a list of 5 copies . 50-198 show 199 offer with random copy (list)
  
  Case 03 : % symbol with upto 3 numbers before it 
  Offer will be shown with higher value 
  If percentage scanned is higher than our highest percentage offer our highest percentage offer will be shown
  Some ads have 30-40% off , in this case we have to take 40 and leave others. 
  Some ads will also have 85.50% off.
  Anything below our minimum offer ,we show 20% offer. 
   */

  const checkIfHasNumber = (word, textArray, index) => {
    //get 50 % off
    //get 40-50% off
    //get 40-50 % off
    //get 50%
    //three %

    if (word.length == 1) {

      let value = textArray[index - 1];
      if(value.includes("-")){
        value = value.split("-")[1];



      }

      const percentValue = !isNaN(Number(value))
        ? Number(value)
        : 0;

      return percentValue;
    } else {
      let value = word.replace("%",'');
      if(value.includes("-")|| value.includes(" ") || value.includes("\n")){
        let valueArray = value.split(/[-\s\n]/);
        for (let i = 0; i < valueArray.length; i++) {
          let num = parseFloat(valueArray[i]);
          if (!isNaN(num)) {
            value = num; // Assign the numerical value to the variable `value`
            break; // Exit the loop once a numerical value is found
          }
        }
      }

      return !isNaN(Number(value))
      ? Number(value)
      : 0;
    }

    //'%' ['123'],70%, 12-30%  , 12-30 %
    /* if(word.length==1){

    const percentValue = !isNaN(Number(textArray[index - 1].split)) ? Number(textArray[index - 1]) : 0;

    return percentValue;

    // if indexValue contains "-"
    // split value using into array using same logic  
    // and pick the first value ( after checking it can be converted to number)
    
    //if doesn't contain
    // get the first element only.

    }else {
     
     

    }
     */

    return true;
  };

  const setNoOfferFound = () => {
   
    setOfferType(-1);
    setOfferHeadline("Sorry looks like there's no offer");
    setPages("no-offer");
    setIsOpen(true);
    setTimeout(()=>{
      setLoading(false);
    },500);
  };

  const fetchOffers = (amount, type) => {
    console.log(amount,"-1111111111111111")
    let respectiveOffers = offerList.filter((item) => item.Type == type);
    let newOffer = null;
    if (type == "ByPrice") {
      respectiveOffers = respectiveOffers
        .sort((a, b) => Number(a.Amount) - Number(b.Amount))
        .reverse();
   
      if (amount > 7999) {
        respectiveOffers = respectiveOffers.filter(
          (item) => Number(item.Amount) >= 5000
        );

        newOffer =
          respectiveOffers[Math.floor(Math.random() * respectiveOffers.length)];
      } else {
        newOffer = respectiveOffers.find((item) => {
          return Number(item.Amount) <= amount;
        });
      }

      if (newOffer) {
        return newOffer;
      } else {
        return respectiveOffers[0];
      }
    } else {


      if(amount < 5){
        respectiveOffers = respectiveOffers.filter(item=>Number(item.percent)==20 );

        newOffer = respectiveOffers[Math.floor(Math.random() * respectiveOffers.length)];
        return newOffer;

      }else if(amount >= 70){
        respectiveOffers = respectiveOffers.filter(item=>Number(item.percent)==70 );

        newOffer = respectiveOffers[Math.floor(Math.random() * respectiveOffers.length)];
        return newOffer;


      }else{

    
        respectiveOffers = respectiveOffers.filter(item=>Number(item.percent) >=amount  && Number(item.high)>=amount && Number(item.low) <=amount  );




        if(respectiveOffers.length){
          newOffer = respectiveOffers[Math.floor(Math.random() * respectiveOffers.length)];
          return newOffer;
        }else{
      return null;
        }
      

      }
  

      







    }
  };

  const getPage = (page) => {
    switch (page) {
      case "offer":
        return (
          <Offer
            image={image}
            setPage={()=>{
              setPages(null);
              setOffer({});
            }}
            offer={offer}
            scannedValue={offerHeadline}
           
          />
        );
      case "min-offer":
        return (
          <MinOffer
            setPage={()=>{
              setPages(null);
              setOffer({});
            }}
          
          />
        );
      case "no-offer":
        return (
          <NoOffer
            image={image}
            setPage={()=>{
              setPages(null);
              setOffer({});
            }}
            offer={offer}
           
          />
        );
      case "max-offer":
        return (
          <MaxOffer
            image={image}
            setPage={()=>{
              setPages(null);
              setOffer({});
            }}
          
          />
        );
      default:
        return null;
    }
  };

 
  

  // const downloadRecording = () => {
  //   const pathName = `${downloadRecordingPath}_${recordingNumber}.${downloadRecordingType}`;
  //   try {
  //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //       // for IE
  //       window.navigator.msSaveOrOpenBlob(mediaBlobUrl, pathName);
  //     } else {
  //       // for Chrome
  //       const link = document.createElement("a");
  //       link.href = mediaBlobUrl;
  //       link.download = pathName;
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return <>
   {isLoading ? (
    <div className="loading-container">
      <Bars
        height="80"
        width="80"
        color="#D4581D"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
      <p>Dream destinations arriving in 3..2..1</p>
    </div>
  ) : ''}
      <Wrapper>
        <Header />
        <div className="camera">
          <div>
          <p 
          style={{
            position:"absolute",
            top: "74%",
            left: "22%",
            color: "white",
            fontSize: "22px"}}
          >Scan ads. Unlock offers.</p>
          <Camera
            ref={camera}
            aspectRatio="cover"
            facingMode="environment"
            numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
            videoSourceDeviceId={activeDeviceId}
            errorMessages={{
              noCameraAccessible:
                "No camera device accessible. Please connect your camera or try a different browser.",
              permissionDenied:
                "Permission denied. Please refresh and give camera permission.",
              switchCamera:
                "It is not possible to switch camera to different one because there is only one video device accessible.",
              canvas: "Canvas is not supported.",
            }}
          />
           
            <div
           style={{position:"absolute",
           height:"2px",
           left: "12%",
           width:"77%",
           background:"white",
           animation:"mymove 2s infinite"
     
     }}>
    </div>
          
          </div>
          
        </div>
       
        <Control_inner>
       
          <button
            className="btn btn-primary"
            style={{ color: "black" }}
            onClick={() => {
              gaEvent('Land me a better deal','');
              if (camera.current) {
                const photo = camera.current.takePhoto();
                getText(photo)
                console.log("done!!!!", camera.current);
                setImage(photo);
              }
            }}

          >
            <img src="./Assets/Black_Tick.png" id="tick"></img>&nbsp;Unlock my offer
          </button>
        </Control_inner>
      </Wrapper>
      {/* {viewRecording()} */}
      {page && <div className="offer-modal">{getPage(page)}</div>}
    </>
  
};

export default App;
