import React, { createRef, useEffect, useState } from "react";
import {
  faArrowLeftLong,
  faCheckSquare,
  faCloudShowersHeavy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import { useScreenshot } from "use-react-screenshot";
import { RWebShare } from "react-web-share";
import { uploadToS3 } from "./awsS3";
import { apis } from "../../../api";
import { gaEvent } from "../../..";
const base_URL = ".././data.json";
const ref = createRef(null);





const Offer = ({
  image,
  setPage,
  offer,
  scannedValue,

  // stopRecord,
  // viewRecording,
}) => {
  const [offerImage, takeScreenshot] = useScreenshot();
  const [shareFile, setShareFile] = useState(null);
  const getImage = async () => takeScreenshot(ref.current);
  const tripConfirm = () => {
    gaEvent('I want this','');
    window.open(offer ? offer.WebLink : "https://www.cleartrip.com/", "_blank");
  };
  const scanAgain = () => {
    gaEvent('Scan Again','');
    setPage(null);
  };
  const shareThis = () => {
    setPage("max-offer");
  };



  const dataURLtoFile = async (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    if (offerImage) {
      handleShare();
    }
  }, [offerImage]);






  const handleShare = async () => {
    const UID = Math.round(1 + Math.random() * (1000000 - 1));
    var imagePayload = {
      fileExt: "png",
      imageID: UID,
      folder: UID,
      img: offerImage,
    };
    await apis.uploadImage(imagePayload).catch((err) => {
      console.log('unable to upload image',err);
    
    });



    const data = {
      title: "Cleartrip Offer",
      text: `Whoa, it works! This site turned a discount I scanned into a travel offer :o Well done @cleartrip! Packing my bags to ✈️Scan any ad here: https://ortraveloncleartrip.com/\n `,
    };
    try {
      /*   if (!navigator.canShare(data)) {
          console.error("Can't share");
        } */
      await navigator.share(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div ref={ref} className="page-style-offer">
      {/* C:\Users\pram5\Downloads\ocr\public\Assets\clouds.png */}
      <Header />
      {/* <img src='./Assets/clouds.png' id='clouds'></img> */}
      {/* {mediaBlobUrl} */}
      {/* <video src={mediaBlobUrl} controls={true} style={{width:"20%",height:"20%"}}></video> */}
      <div className="main-cont">
        <div className="offer-row">
          <div className="offer-col1">
            <img src={image}></img>
            {/* <img src='./Assets/Image_Top-Left.png'></img> */}
          </div>
          <div className="offer-col2">
            <p>{offer?.Title}</p>
          </div>
        </div>
        <div className="offer-row2">
          <img src={`./offerImages/${offer?.SNo}.png`}></img>
        </div>
      </div>
      <div className="offer-btn">
        <button onClick={tripConfirm} id="offer-btn" style={{ color: "black" }}>
          <p>
            <img src="./Assets/Black_Tick.png" id="tick"></img>&nbsp;I want this
            trip
          </p>
        </button>
        <button onClick={scanAgain} id="offer-btn">
          <p>I want to scan more</p>
        </button>
        <button onClick={()=>{
          
          if(offerImage){
            handleShare();
          }else{
            getImage()
          }
          
          }} id="offer-btn">
        <p>Let me share this</p>
        </button>
      
    {/*     <RWebShare
          data={{
            text: "Like humans, flamingos make friends for life",
            url: offerImage,
            title: "Flamingos",
          }}
          onClick={() => console.log("shared successfully!")}
        >
          <button id="offer-btn" style={{ marginBottom: "20px" }}>
            Share Video
          </button>
        </RWebShare> */}
      </div>
      <img src="./Assets/clouds.png" id="clouds"></img>
    </div>
  );
};
export default Offer;
