import React, { useEffect, useState } from 'react'

export default function Share() {
    const openGraphData = {
        title: "Cleartrip Offer",
        description:
          `Whoa, it works! This site turned a discount I scanned into a travel offer :o Well done @cleartrip! Packing my bags to ✈️
          Scan any ad here: https://ortraveloncleartrip.com/`,
        url: 'https://ortraveloncleartrip.com/',
        image:
          'https://upload.wikimedia.org/wikipedia/commons/7/72/Open_Graph_protocol_logo.png',
      };

      const [offerImage,setOfferImage] = useState('');
    const getParam=(param)=>{
        return new URLSearchParams(window.location.search).get(param);
      }


      useEffect(()=>{

        const image =  getParam('sharedImage');
        console.log(image);
        setOfferImage(image||`https://upload.wikimedia.org/wikipedia/commons/7/72/Open_Graph_protocol_logo.png`);

      },[])

 

  return (
   <>
    <head>
      <meta property="og:title" content={openGraphData.title} />
      <meta property="og:description" content={openGraphData.description} />
      <meta property="og:url" content={openGraphData.url} />
      <meta property="og:image" content={offerImage} />
      <meta name="twitter:card" content="summary" />
    </head>
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height: '100vh'}}>
    <img style={{width:'100%'}} src={offerImage} alt="cleartrip" />
    </div>
  </>
  )
}
