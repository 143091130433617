import React from 'react';
import {  PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";
import App from "./App";
import './index.css'
import Condition from './Camera/components/pages/Condition';

const LoadingScreen =()=>{

    const [loading, setLoading]=useState(false)
    const [page,setPage] = useState('landingPage')
    useEffect(()=>{
       setLoading(true)
       setTimeout(() => {
        setLoading(false)
       }, 500);
    },[])
    return(
        <div >
            {loading?
            <div className="loading">
            <PuffLoader size={80} color={"#D4581D"} loading={loading}/>
            </div>

            : page=='landingPage' ?<Condition setPage={setPage}/>: <App/>
            }
            {/* <ScanOffer setPage={setPage}/> */}
 </div>
    ) 
    
}

export default LoadingScreen