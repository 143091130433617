import {
  faArrowLeftLong,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Header from "./Header";
import "../../../index.css";

export default function MaxOffer({
  image,
  setPage,

}) {
  const tripConfirm = () => {
    setPage();
  };
  const scanAgain = () => {
    setPage(null);
  };
  const shareThis = () => {
    console.log("/MaxOffer");
  };


  return (
    <div className="page-style">
      {/* C:\Users\pram5\Downloads\ocr\public\Assets\clouds.png */}
      <Header />
      <img src="./Assets/clouds.png" id="clouds"></img>
      <div className="main-cont">
        <div className="offer-row">
          <div className="offer-col1">
            {/* <img src={image}></img> */}
            <img src="./Assets/Image_Top-Left.png"></img>
          </div>
          <div className="offer-col2">
            <p>
              Excuse me, brother! Iss product par paise phirane ke bajaye cochin
              phirke aa jao.
            </p>
          </div>
        </div>
        <div className="offer-row2">
          <img src="./Assets/Image_Right-2.png"></img>
        </div>
      </div>
      <div className="offer-btn">
        <button onClick={tripConfirm} id="offer-btn">
          <p>
            <img src="./Assets/Orange_Tick.png" id="tick"></img>&nbsp;I want
            this trip
          </p>
        </button>
        <button onClick={scanAgain} id="offer-btn">
          <p>I want to scan more</p>
        </button>
        <button onClick={shareThis} id="offer-btn">
          <p>Let me share this</p>
        </button>
      </div>
    </div>
  );
}
