import React from 'react'
import Header from './Header'
import './scanOffer.css'
import './windowPage.css'
import '../../../index.css'

export default function () {

  return (
    <div className='window-bg'>
        <div className="header-wd">
    <div className="header-row-wd">
       <img src="./Assets/Logo.png" style={{height:"4vh",width:"11vw",padding:"1rem 0rem 0rem 2rem"}}></img>
       <img src="./Assets/Tagline.png" style={{height:"7vh" ,width: "34vw",padding:"1rem 1rem 0rem 0rem"}}></img>
       </div>
    </div>
        <div className='window-flex'>
          <div className='big-bill'>
            <img src='./Assets/Big-Billion.png'></img>
            </div>
        <div className='mobile'>
        <img src='./Assets/Mobile.png'></img>
        </div>
        <div className='win-text'>
            <h1>Uh oh.</h1>
            <p className='tx-rg'>Yeh desktop pe     experience karna doglapan hai.</p>
            <p className='tx-bd'>Scan this through    your phone to unlock the best experience.</p>
            <p><img style={{width: '35%'}} src='./Assets/qr.svg'></img></p>
        </div>
        </div>
        {/* <img src='./Assets/Website_BG.png'  id='bg-image'></img> */}
        
    </div>
  )
}
