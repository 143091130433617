import React, { useEffect, useState } from 'react'
import LoadingScreen from './LoadingScreen'
import Share from './Share';
// Import the functions you need from the SDKs you need


export default function () {

 
  const [isShared,setShared] = useState(false);
  const getParam=(param)=>{
    return new URLSearchParams(window.location.search).get(param);
  }

  useEffect(()=>{


    if(getParam('shared')){
      setShared(true);
    }
    

  },[]);

  return (
    <div>
      {isShared ? <Share/> :  <LoadingScreen/>}
    </div>
  )
}
