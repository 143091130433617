import React from 'react';
import ScanOffer from './ScanOffer';
import {useEffect, useState} from 'react'
import WindowPage from './WindowPage';
export default function ({setPage}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 950);
    useEffect(() => {
      window.addEventListener(
        "resize",
        () => {
          const ismobile = window.innerWidth < 950;
          if (ismobile !== isMobile) setIsMobile(ismobile);
        },false);
    }, [isMobile]);
  return (
    <> {isMobile?<ScanOffer setPage={setPage}/>:<WindowPage/>}</>
   
  )
}
