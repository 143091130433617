import { decode } from "querystring";
import S3 from "react-aws-s3";
const config = {
  bucketName: "ocr-images-clear-trip",
  dirName: "",
  region: "ap-south-1",
  accessKeyId: "AKIAT5XU4BI7VYUXWN5X",
  secretAccessKey: "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",
};

const ReactS3Client = new S3(config);
/*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

export const uploadToS3 = async (file, newFileName) => {
  
  return ReactS3Client.uploadFile(file, newFileName).catch((err) =>
    console.error(err)
  );
};

export const deleteFromS3 = async (filename) => {
  return ReactS3Client.deleteFile(filename)
    .then((response) => console.log(response))
    .catch((err) => console.error(err));
};
