import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Main from './Main';
import { initializeApp } from "firebase/app";
import { getAnalytics,logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCYm_hZh_H51ldm5E4mrvYCZ4zwf1MoEB8",
  authDomain: "cleartrip-768f9.firebaseapp.com",
  projectId: "cleartrip-768f9",
  storageBucket: "cleartrip-768f9.appspot.com",
  messagingSenderId: "237743774515",
  appId: "1:237743774515:web:7b992335d79be6fc4e7c46",
  measurementId: "G-VVTNZD62S5"
};

// Initialize Firebase
export const appGoogle = initializeApp(firebaseConfig);
export const analytics = getAnalytics(appGoogle);
export const gaEvent = (eventName,category)=>{
    logEvent(analytics, eventName, { name: category});

}
ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
