import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import Header from "./Header";
import "../../../index.css";

export default function MinOffer({
  setPage,

}) {
  const changeHandler = () => {
    setPage(null);
  };


  return (
    <div className="page-style">
      <Header />
      <div className="offer-row">
        <div className="offer-col1">
          <img src="./Assets/iphone14.jpg"></img>
        </div>
        <div className="offer-col2">
          <p>
            Did we just time travel?
            <br />
            <br />
            Because an offer for this price only belongs to the 1920s
            <br />
            <br />
            <b>Please scan for &#x20B9; 199 or more.</b>
          </p>
        </div>
      </div>
      <div className="min-row">
        <div className="min-col1">
          <img src="./Assets/katrina.jpg"></img>
        </div>
        <div className="min-col2">
          <button onClick={changeHandler} id="min-btn">
            let me scan again
          </button>
        </div>
      </div>
    </div>
  );
}
